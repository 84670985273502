@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@800&display=swap');

@layer base {
  html {
    font-size: 18px;
    height: 100%;
  }

  body {
    margin: 0;
    padding: 0;
    height: 100%;
    background: #fefefe;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    color: #424242;
    -webkit-font-smoothing: antialiased;
    @apply text-base;
  }

  h1 {
    @apply text-5xl;
  }

  h2 {
    @apply text-3xl;
  }

  h3 {
    @apply text-2xl;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: Raleway, Roboto, sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 1.2rem;
    color: #212121;
  }

  table,
  th,
  tr,
  td {
    border: solid #d1d5db 1px;
    @apply py-2 px-3;
  }

  input,
  input.MuiOutlinedInput-input,
  div.MuiOutlinedInput-input,
  input.MuiInputBase-input,
  input.MuiSelect-nativeInput,
  select {
    @apply border border-gray-300 rounded p-3 w-full text-sm h-auto;
  }

  fieldset.MuiOutlinedInput-notchedOutline {
    @apply border-gray-300;
  }

  label {
    @apply block;
  }

  hr {
    @apply my-6;
  }
}

@layer components {
  .card {
    @apply bg-white rounded-lg shadow-lg p-5 lg:p-8;
  }
  .card-sm {
    @apply bg-white rounded-md shadow-md p-4 lg:p-5;
  }

  .link {
    text-decoration: underline;
    font-weight: 700;
    @apply text-fb_blue underline-offset-2 cursor-pointer;
  }

  .table-container {
    overflow-x: auto;
    width: 100%;
  }
}

.lead-text {
  @apply text-lg;
}

.jumbo-text {
  font-size: 60px;
  font-weight: 800;
}

.grid-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

/* Buttons */
.button {
  @apply bg-fb_primary py-3 px-8 inline-block border-2 border-fb_primary text-white font-bold rounded-lg;
}

.button-small {
  @apply text-sm py-2 px-5 font-semibold;
}
.button-large {
  @apply text-lg py-5 px-10;
}

.button-full {
  @apply w-full text-center;
}

.button-hollow {
  @apply text-fb_primary bg-white;
}

.button-secondary {
  @apply bg-fb_secondary text-white  border-fb_secondary;
}

.button-secondary.button-hollow {
  @apply text-fb_secondary bg-white border-fb_secondary;
}

.button-disabled {
  @apply bg-gray-300 text-white border-gray-300 cursor-not-allowed;
}

/* Forms */
.form-field {
  @apply mb-4;
}

/* Homepage */
.home-hero {
  background-image: url('../assets/cafe.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right top;
  padding: 9rem 0;
}

#basic-menu .MuiPaper-elevation8 {
  @apply shadow-lg text-sm;
}
